.coming-soon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
}

h1 {
  font-size: 3em;
  margin-bottom: 10px;
}
h2 {
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 250px;
  margin-top: 20px;
}

.countdown-timer {
  margin-top: 30px;
  font-size: 2em;
  font-weight: bold;
  color: #e74c3c;
}
