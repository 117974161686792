* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #ffffff !important; /* Forces white background */
  color: #333;
  font-family: "Arial", sans-serif;
  overflow-x: hidden; /* Prevents unwanted horizontal scrolling */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
